import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../components/commen/Loading";
import Banners from "../components/VideoCards/Banners";
import VideoCard from "../components/VideoCards/VideoCard";
import {
  getAllGenre,
  getCategoryData,
  getPoster,
  getRecentWatch,
} from "../utils/apis";
import useStore from "../utils/store";
import { AiOutlineClose } from "react-icons/ai";
import { useAddToHomescreenPrompt } from "../components/usepwa";

const Home = () => {
  const activeCategory = useStore((state) => state.activeCategory);

  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState(null);
  const [recentWatch, setRecentWatch] = React.useState([]);
  const [genre, setGenre] = React.useState(null);
  const user = useStore((state) => state.user);
  const [poster, setPoster] = React.useState(null);
  const getCategoryDataHandler = async () => {
    setLoading(true);
    const [success, data] = await getCategoryData(activeCategory);
    if (success) {
      setData(data);
    }
    setLoading(false);
  };

  const getRecentWatchHandler = async () => {
    setLoading(true);
    const [success, data] = await getRecentWatch(user?.token);
    if (success) {
      setRecentWatch(data);
    }
    setLoading(false);
  };

  const getPosterHandler = async () => {
    const [success, data] = await getPoster();
    if (success) {
      setPoster(data);
    }
  };

  const getAllGenreHandler = async () => {
    setLoading(true);
    const [success, data] = await getAllGenre();
    if (success) {
      setGenre(data);
    }
    setLoading(false);
  };

  const randomColors = [
    "bg-[#0C356A]",
    "bg-[#3D0C11]",
    "bg-[#940B92]",
    "bg-[#005B41]",
    "bg-[#445D48]",
    "bg-[#F05941]",
    "bg-[#662549]",
  ];
  const [prompt, promptToInstall] = useAddToHomescreenPrompt();
  const [isVisible, setVisibleState] = React.useState(false);

  const hide = () => setVisibleState(false);

  React.useEffect(() => {
    if (prompt) {
      // setVisibleState(true);
    }
  }, [prompt]);
  React.useEffect(() => {
    if (activeCategory) {
      getCategoryDataHandler();
      getAllGenreHandler();
      getPosterHandler();
    }
  }, [activeCategory]);

  React.useEffect(() => {
    if (user) {
      getRecentWatchHandler();
    }
  }, [user?.token]);

  if (loading) return <Loading />;
  return (
    <div className="relative flex flex-1 flex-col gap-2">
      <div
        className={`
        ${isVisible ? "fixed" : "hidden"} bottom-0 left-0 right-0
        top-0 z-50 flex flex-col items-center justify-center gap-2 bg-black bg-opacity-50 px-3 py-4 md:hidden md:px-6
      `}
      >
        <div className="mx-auto rounded-lg bg-white p-5 shadow-lg">
          <div className="flex flex-col items-center justify-center gap-2">
            <div className="text-center text-2xl font-bold">
              Add to Home Screen
            </div>
            <div className="text-center text-sm">
              Add our app to your home screen to get started
            </div>
            <div className="flex flex-row items-center justify-center gap-2">
              <button
                className="flex items-center justify-center gap-2 rounded bg-primary-100 px-3 py-2 text-white"
                onClick={promptToInstall}
              >
                {/* <img src={img1} alt="appstore" className="h-6" /> */}
                <div>Add</div>
              </button>
              <button
                className="flex items-center justify-center gap-2 rounded bg-primary-100 px-3 py-2 text-white"
                onClick={hide}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      <Banners data={data?.banners} />

      <div className="flex flex-wrap items-center justify-center gap-6 px-3 py-4 md:px-6">
        {genre?.map((item, index) => {
          return (
            <Link
              to={`/genre/${item.name}`}
              className={`flex items-center justify-center rounded 
                ${randomColors[index % (randomColors.length - 1)]}
              px-4 py-2  text-white`}
            >
              {item.name}
            </Link>
          );
        })}
      </div>
      {/* {recentWatch?.length > 0 && (
        <VideoCard
          data={{
            name: "Continue Watching",
            content: recentWatch,
            imageType: "Horizontal",
          }}
        />
      )} */}
      {data?.subcategories?.map((item) => {
        return <VideoCard data={item} />;
      })}
      {poster?.image && <Poster data={poster} setData={setPoster} />}
    </div>
  );
};

export default Home;

const Poster = ({ data, setData }) => {
  return (
    <div className="fixed  bottom-0 left-0 right-0 top-0 z-50 flex flex-col items-center justify-center gap-2 px-3 py-4 md:px-6">
      <div className="relative flex flex-row flex-wrap gap-4">
        <button className=" flex-col  gap-2 md:w-auto">
          <img
            src={data?.image}
            alt="banner"
            className="aspect-[9/16] max-h-[90dvh]"
          />
        </button>
        <button
          className="absolute -right-4 -top-4 rounded-full border bg-white p-2"
          onClick={() => {
            setData(null);
          }}
        >
          <AiOutlineClose className="" />
        </button>
      </div>
    </div>
  );
};
