import React from "react";
import { useLocation } from "react-router-dom";
import Loading from "../../components/commen/Loading";
import useValidate from "../../hooks/useValidate";
import {
  getSubscription,
  updateSubscription,
} from "../../utils/apis";
import { Card } from "../AllSubscrition";

const Subscription = () => {
  const { state } = useLocation();
  const [otherSubscriptions, setOtherSubscriptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [ownSubscriptions, setOwnSubscriptions] = React.useState({});
  const [update, setUpdate] = React.useState(false);
  const getOwnSubscriptions = async () => {
    setLoading(true);
    const [success, response] = await getSubscription(user.token);

    if (success) {
      setOwnSubscriptions(response);
    }
    setLoading(false);
  };

  async function updateSubscriptionHandler(id) {
    setLoading(true);
    const [success, response] = await updateSubscription(user.token, {
      subscriptionId: id,
    });
    if (success) {
      setUpdate(true);
      getOwnSubscriptions();
    }
    setLoading(false);
  }
  const [user, navigate] = useValidate(() => {
    if (!state) return;
    getOwnSubscriptions();
  });

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="flex flex-1 flex-col items-center justify-center gap-6 py-10">
      <div className="flex w-full flex-col items-center gap-4">
        <p className="text-xl font-semibold text-white">Your Subscription</p>
        <div className="grid w-[80%] grid-flow-row grid-cols-3 gap-10">
          <Card
            data={ownSubscriptions.subscription}
            own={{
              valid: ownSubscriptions?.subscriptionValid,
              time: ownSubscriptions?.timeLeft,
            }}
          />
        </div>
      </div>

      {!update && (
        <div className="flex w-full flex-col items-center gap-4">
          <p className="text-xl font-semibold text-white">
            Buy these Subscription
          </p>
          {state?.subs?.length === 0 ? (
            <p className="text-xl font-semibold text-white">
              No Subscription Available
            </p>
          ) : (
            <div className="grid w-[80%] grid-flow-row grid-cols-3 gap-10">
              {state?.subs?.map((item) => (
                <Card
                  data={item}
                  onClick={() => updateSubscriptionHandler(item._id)}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Subscription;

// const Card = ({ data, own, onClick }) => {
//   const convertSeconds = (miliseconds) => {
//     // convert miliseconds to date from now
//     const date = new Date();
//     date.setTime(date.getTime() + miliseconds * 1000);
//     return date.toLocaleDateString();
//   };

//   return (
//     <div className=" h-fit rounded-md bg-white">
//       <div className=" rounded-t-md bg-gradient-to-r from-primary-100 via-primary-100 to-primary2 py-5 px-10 text-center text-sm font-semibold uppercase text-white  ">
//         {data?.title}
//       </div>
//       <div className="flex flex-col gap-3 px-4 pt-4 pb-2 ">
//         {!own && <CardItem title="Old Price" value={data?.price} old />}
//         <CardItem title="Price" value={!own ? data?.newPrice : data?.price} />
//         {!own && (
//           <CardItem
//             title="Old Validity"
//             value={!own ? data?.newValidity : data?.validity}
//             old
//           />
//         )}
//         <CardItem title="Validity" value={data?.validity + " " + "days"} />
//         <CardItem title="Movies" value={data?.moviesCount} />
//         <CardItem title="Series" value={data?.seriesCount} />
//         {own && (
//           <CardItem
//             title="Valid"
//             value={
//               own?.valid ? "Valid till " + convertSeconds(own?.time) : "Expired"
//             }
//           />
//         )}
//       </div>
//       {!own && (
//         <div className="flex flex-row items-center justify-center pt-2  pb-4">
//           <button
//             onClick={onClick}
//             className="rounded-md bg-primary-100 px-4 py-2 font-semibold text-white hover:bg-opacity-80"
//           >
//             Subscribe
//           </button>
//         </div>
//       )}
//     </div>
//   );
// };

// const CardItem = ({ title, value, old }) => {
//   return (
//     <div className="flex flex-row items-center justify-between gap-3">
//       <p className="text-sm font-[600]">{title}</p>
//       <p
//         className={`${
//           old ? "text-gray-500 line-through" : "text-primary-100"
//         } font-[600]  `}
//       >
//         {value}
//       </p>
//     </div>
//   );
// };
