import React from "react";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import ReactPlayer from "react-player/youtube";
import { useParams } from "react-router-dom";
import Loading from "../components/commen/Loading";

import { addMovieView, getUpcomingDetails } from "../utils/apis";
import useStore from "../utils/store";

import VideoJS from "../components/Videojs";
const UpDetails = () => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState({});
  const user = useStore((state) => state.user);

  const [description, setDescription] = React.useState(false);
  const [url, setUrl] = React.useState("");
  const { id: id2 } = useParams();
  const [id, setId] = React.useState(0);
  const getMovieDetailsHandler = async () => {
    const [success, response] = await getUpcomingDetails(id2);
    if (success) {
      setData(response);
      setId(response._id);
      setUrl(response.trailer);
    }
  };

  const movieviewHandler = async () => {
    const [success, response] = await addMovieView(user?.token, id);
    if (success) {
    }
  };

  const playerRef = React.useRef(null);

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,

    sources: [
      {
        //m3u8
        src: data?.trailer,
        type: "application/x-mpegURL",
      },
    ],
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on("waiting", () => {
      // videojs.log('player is waiting');
    });

    player.on("dispose", () => {
      // videojs.log('player will dispose');
    });
  };

  React.useEffect(() => {
    setLoading(true);
    getMovieDetailsHandler();
    if (id !== 0) {
      movieviewHandler();
    }

    setLoading(false);
  }, [id, id2]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="md:px-32">
      <div className="relative aspect-video w-full object-cover md:aspect-[10/4]">
        {/* <div className="absolute top-0 right-0 left-0 z-10 h-[] bg-primary-100"></div> */}
        {!url || data.trailer === "undefined" ? (
          <img
            src={data?.horizontalBanner}
            alt="poster"
            className="h-full w-full bg-black object-contain"
          />
        ) : url.includes("www.youtube.com") ? (
          <ReactPlayer url={url} controls={true} playing={true} width="100%" />
        ) : (
          <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
        )}
      </div>
      <div className="  flex flex-row flex-wrap items-center justify-between gap-6 rounded px-8 py-3 text-white md:bg-primary-100 md:py-1.5 ">
        <div className="flex flex-row flex-wrap items-center gap-6">
          <p className="text-sm font-[600]">{data?.name}</p>
          <div className="flex gap-1">
            {data?.genres?.map((item, index) => (
              <>
                <p className="text-xs font-[400]">{item.name}</p>
                {data?.genres?.length > 1 &&
                  data?.genres?.length !== index + 1 && (
                    <p className="text-xs font-[400]">|</p>
                  )}
              </>
            ))}
          </div>
          <p className="text-xs font-[400]">{data?.duration}</p>
          <p className="text-xs font-[400]">{data?.language}</p>
          <p className="text-xs font-[400]">{data?.releaseYear}</p>
        </div>
      </div>
      <div className="flex flex-col  gap-3 px-8 py-4 text-white md:py-8">
        <div className="flex flex-col gap-3">
          <div className="text-xs">
            Rating:
            <br />
            <div className="mt-1 flex gap-1">
              {new Array(5)
                .fill(0)
                .map((item, index) =>
                  +data?.rating > index ? (
                    <AiFillStar className="text-primary-100" />
                  ) : (
                    <AiOutlineStar className="text-primary-100" />
                  )
                )}
            </div>
          </div>
          <p className="text-xs">
            Description:
            <br />
            <span className="text-xs font-[400] text-text">
              {data?.description?.slice(
                0,
                description ? data?.description?.length : 100
              )}
              <button
                className="ml-1 text-xs font-[400] text-primary-100"
                onClick={() => setDescription(!description)}
              >
                See {description ? "less" : "more"}
              </button>
            </span>
          </p>
          <p className="text-xs">
            Age-Limit:
            <br />
            <span className="text-xs font-[400] text-text">
              {data?.ageLimit}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default UpDetails;
