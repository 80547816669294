import React, { useEffect, useState } from "react";
import Loading from "../components/commen/Loading";
import { getUpcoming } from "../utils/apis";
import { useNavigate } from "react-router-dom";
import Void from "../assets/void.svg";
const Upcoming = () => {
  const [upcoming, setUpcoming] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const getUpcomingHandler = async () => {
    const [success, data] = await getUpcoming();
    if (success) {
      setUpcoming(data);
    }
    setLoading(false);
  };
  useEffect(() => {
    getUpcomingHandler();
  }, []);


  if (loading) {
    return <Loading />;
  }

  return (
    <div className="flex flex-1  flex-col gap-3 px-4 py-6 md:px-8">
      <h1 className="text-lg font-bold text-text">Upcoming</h1>
      {upcoming.length > 0 ? (
        <div className="flex flex-row flex-wrap gap-4">
          {upcoming.map((item) => (
            <button
              className="w-[46%] flex-col  gap-2 md:w-auto"
              onClick={() => {
                navigate(`/upcoming/${item.slug}`);
              }}
            >
              <img
                src={item?.horizontalBanner}
                alt="banner"
                className="h-24 w-[1/2]  cursor-pointer rounded-md object-cover transition-all hover:scale-110 md:h-28 md:w-52"
              />
              <p className="text-start text-sm font-semibold text-text">
                {item.name}
              </p>
            </button>
          ))}
        </div>
      ) : (
        <div
          className="
        flex flex-col items-center justify-center gap-2
        "
        >
          <img src={Void} alt="void" className=" w-60" />
          <p className="text-center text-text">No Data Found</p>
        </div>
      )}
    </div>
  );
};

export default Upcoming;
