import React from "react";
import { getAllMovies, getAllSeries } from "../utils/apis";
import Loading from "../components/commen/Loading";
import VideoCard from "../components/VideoCards/VideoCard";
import { useParams } from "react-router-dom";
import Void from "../assets/void.svg";
const Genre = () => {
  const [loading, setLoading] = React.useState(true);
  const [movies, setMovies] = React.useState([]);
  const [series, setSeries] = React.useState([]);
  const params = useParams();
  const getData = async () => {
    const [success, data] = await getAllMovies();
    const [success2, data2] = await getAllSeries();
    if (success) {
      setMovies(
        data?.filter((item) => {
          return item?.genres?.find((item2) => {
            return item2?.name === params?.name;
          });
        })
      );
    }
    if (success2) {
      setSeries(
        data2?.filter((item) => {
          return item?.genres?.find((item2) => {
            return item2?.name === params?.name;
          });
        })
      );
    }
    setLoading(false);
  };

  React.useEffect(() => {
    getData();
  }, [params?.name]);
 
  if (loading) return <Loading />;
  return (
    <div className="flex flex-1 flex-col gap-3 py-6">
      {movies?.length > 0 && (
        <VideoCard
          data={{
            name: "Movies",
            content: movies?.map((item) => {
              return {
                item: item,
                kind: "movie",
              };
            }),
          }}
        />
      )}
      {series?.length > 0 && (
        <VideoCard
          data={{
            name: "Series",
            content: series?.map((item) => {
              return {
                item: item,
                kind: "series",
              };
            }),
          }}
        />
      )}

      {movies?.length === 0 && series?.length === 0 && (
        <div
          className=" 
        flex flex-col items-center justify-center gap-2
        "
        >
          <img src={Void} alt="void" className=" w-60" />
          <p className="text-center text-text">No Data Found</p>
        </div>
      )}
    </div>
  );
};

export default Genre;
