import React, { useEffect } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { LuCopy, LuCopyCheck } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import {
  addReferral,
  getProfile,
  getReferralChain,
  updateKyc,
  updateUserData,
} from "../utils/apis";
import useStore from "../utils/store";

const Referral = () => {
  const navigate = useNavigate();
  const user = useStore((state) => state.user);
  const [number, setNumber] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [error, setError] = React.useState("");
  const [profile, setProfile] = React.useState({});
  const [loading2, setLoading2] = React.useState(false);
  const [chain, setChain] = React.useState("");
  const [kyc, setKyc] = React.useState({});
  const [copied, setCopied] = React.useState(false);
  const updateKycHandler = async () => {
    if (kyc?.aadharcardNumber?.trim()?.length < 12) {
      setError("Please enter a valid Aadhar Number");
      return;
    }
    if (kyc?.pancardNumber?.trim().length < 10) {
      setError("Please enter a valid Pan Card Number");
      return;
    }
    if (!kyc?.aadharcardImage) {
      setError("Please upload Aadhar Card");
      return;
    }
    if (!kyc?.pancardImage) {
      setError("Please upload Pan Card");
      return;
    }

    setError("");
    setLoading2(true);
    const formdata = new FormData();
    formdata.append("aadharcardNumber", kyc.aadharcardNumber);
    formdata.append("pancardNumber", kyc.pancardNumber);
    formdata.append("aadharcardImage", kyc.aadharcardImage);
    formdata.append("pancardImage", kyc.pancardImage);
    const [success, response] = await updateKyc(user.token, formdata);
    if (success) {
      getProfileHandler();
    } else {
      setError("Something went wrong, please try again later");
    }
    setLoading2(false);
  };
  const getReferralChainHandler = async () => {
    const [success, response] = await getReferralChain(user.user._id);
    if (success) {
      setChain(response);
    }
  };
  const getProfileHandler = async (update) => {
    setLoading2(true);
    const [success, response] = await getProfile(user.token);

    if (success) {
      setProfile(response);
      if (response.isReferred) {
        getReferralChainHandler();
      }
    }
    setLoading2(false);
  };

  const [loading, setLoading] = React.useState(false);

  console.log(loading2);

  const updatePhoneNumber = async () => {
    if (phoneNumber.trim().length < 10) {
      setError("Please enter a valid number");
      return;
    }
    setError("");
    setLoading(true);
    const [success, response] = await updateUserData(user.token, {
      phoneNumber: `+91${phoneNumber}`,
    });
    console.log(response);

    if (success) {
      getProfileHandler();
    } else {
      setError("Something went wrong, please try again later");
    }
    setLoading(false);
  };

  const handleSubmit = async () => {
    if (number.trim().length < 10) {
      setError("Please enter a valid number");
      return;
    }
    setError("");
    setLoading(true);
    const [success, response] = await addReferral(user.token, {
      referralCode: `+91${number}`,
    });
    console.log(response);

    if (success) {
      if (response.referral) {
        getProfileHandler();
      } else {
        setError(response.message);
      }
    } else {
      setError("Something went wrong, please try again later");
    }
    setLoading(false);
  };

  console.log(error);
  useEffect(() => {
    if (!user?.token) {
      navigate("/login");
    } else {
      getProfileHandler();
    }
  }, [user, navigate]);

  // new Date()  new Date(profile?.subscriptionExpireDate)
  return (
    <main className="flex flex-col items-center justify-center gap-10 px-6 py-10 md:min-h-[100dvh] md:flex-row  md:gap-3 md:px-10">
      <section className="md:w-1/2">
        <h1 className="text-3xl font-bold uppercase leading-tight text-gray-200 md:text-5xl">
          Refer friends. Earn Rewards together
        </h1>
      </section>
      <section className="md:w-1/2">
        {profile?.subscriptionExpireDate &&
        new Date() < new Date(profile?.subscriptionExpireDate) ? (
          profile?.isReferred ? (
            <div className="flex flex-col gap-6 rounded-lg border border-gray-800 bg-secondary3 p-4 text-white">
              <h1 className="text-2xl font-bold">Referral Chain</h1>

              {loading2 && (
                <div className="flex items-center justify-center gap-2 rounded bg-primary-100 py-2 text-white">
                  <AiOutlineLoading3Quarters className="animate-spin" />
                  Loading
                </div>
              )}
              <div
                dangerouslySetInnerHTML={{
                  __html: chain,
                }}
              />

              <div className="flex flex-col gap-4 rounded-lg bg-gray-950 p-3">
                <div className="flex items-center justify-between gap-6">
                  <h1 className="text-lg font-medium">Referral Code</h1>
                  <div className="flex items-center gap-3 ">
                    <p className="text-white">
                      {profile?.phoneNumber.replace("+91", "")}
                    </p>
                    <button
                      onClick={() => {
                        navigator.clipboard.writeText(
                          profile?.phoneNumber.replace("+91", "")
                        );
                        setCopied(true);
                        setTimeout(() => {
                          setCopied(false);
                        }, 2000);
                      }}
                      className={
                        "rounded-full bg-primary-100 p-2 text-white transition-all duration-700  " +
                        (copied ? "bg-green-500" : "")
                      }
                    >
                      {copied ? <LuCopyCheck /> : <LuCopy />}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : profile?.isKycComplete ? (
            profile?.phoneNumber?.length > 0 ? (
              <div className="rounded-lg border border-gray-800 bg-secondary3 p-4 text-white">
                <h1 className="mb-4 text-2xl font-bold">Referral</h1>
                <div className="flex flex-col gap-3">
                  <label className="text-xs font-[400]">
                    Enter Phone Number
                  </label>
                  <input
                    type="tel"
                    onChange={(e) => setNumber(e.target.value)}
                    className="rounded border border-gray-500 bg-secondary3 px-4 py-2 text-white"
                    value={user?.referralCode}
                  />
                  {error && <p className="text-xs text-red-500">{error}</p>}
                  <button
                    onClick={handleSubmit}
                    className="flex items-center justify-center gap-2 rounded bg-primary-100 py-2 text-white"
                  >
                    {(loading || loading2) && (
                      <AiOutlineLoading3Quarters className="animate-spin" />
                    )}
                    Send Referral
                  </button>
                </div>
              </div>
            ) : (
              <div className="rounded-lg border border-gray-800 bg-secondary3 p-4 text-white">
                <h1 className="mb-4 text-2xl font-bold">Referral</h1>

                <div className="flex flex-col gap-3">
                  {error && <p className="text-xs text-red-500">{error}</p>}
                  <label className="text-xs font-[400]">
                    Please update your phone number
                  </label>
                  <input
                    type="tel"
                    className="rounded border border-gray-500 bg-secondary3 px-4 py-2 text-white"
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                  <button
                    onClick={updatePhoneNumber}
                    className="flex items-center justify-center gap-2 rounded bg-primary-100 py-2 text-white"
                  >
                    {(loading || loading2) && (
                      <AiOutlineLoading3Quarters className="animate-spin" />
                    )}
                    Update Phone Number
                  </button>
                </div>
              </div>
            )
          ) : (
            <div className="rounded-lg border border-gray-800 bg-secondary3 p-4 text-white">
              <h1 className="mb-4 text-2xl font-bold">KYC</h1>
              <div className="flex flex-col gap-4">
                {error && <p className="text-xs text-red-500">{error}</p>}
                <div className="flex flex-col gap-2">
                  <label className="text-xs font-[400]">
                    Aadhar Card Number
                  </label>
                  <input
                    type="number"
                    onChange={(e) =>
                      setKyc({ ...kyc, aadharcardNumber: e.target.value })
                    }
                    className="rounded border border-gray-500 bg-secondary3 px-4 py-2 text-white"
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <label className="text-xs font-[400]">
                    Upload Aadhar Card
                  </label>
                  <input
                    type="file"
                    onChange={(e) =>
                      setKyc({ ...kyc, aadharcardImage: e.target.files[0] })
                    }
                    className="rounded border border-gray-500 bg-secondary3 px-4 py-2 text-white"
                  />
                </div>

                <div className="flex flex-col gap-2">
                  <label className="text-xs font-[400]">
                    {" "}
                    Pan Card Number{" "}
                  </label>
                  <input
                    type="text"
                    onChange={(e) =>
                      setKyc({ ...kyc, pancardNumber: e.target.value })
                    }
                    className="rounded border border-gray-500 bg-secondary3 px-4 py-2 text-white"
                  />
                </div>

                <div className="flex flex-col gap-2">
                  <label className="text-xs font-[400]">Upload Pan Card </label>
                  <input
                    type="file"
                    onChange={(e) =>
                      setKyc({ ...kyc, pancardImage: e.target.files[0] })
                    }
                    className="rounded border border-gray-500 bg-secondary3 px-4 py-2 text-white"
                  />
                </div>
                <button
                  onClick={updateKycHandler}
                  disabled={loading}
                  className="flex items-center justify-center gap-2 rounded bg-primary-100 py-2 text-white"
                >
                  {(loading || loading2) && (
                    <AiOutlineLoading3Quarters className="animate-spin" />
                  )}
                  Update KYC
                </button>
              </div>
            </div>
          )
        ) : (
          <div>
            <h1 className="text-2xl font-bold text-white">
              Your subscription has expired Or you have not subscribed yet.
              Please subscribe to continue.
            </h1>
          </div>
        )}
      </section>
    </main>
  );
};

export default Referral;
