import React from "react";
import toast, { LoaderIcon } from "react-hot-toast";
import { addBankDetails, verifyIfsc } from "../utils/apis";
import useStore from "../utils/store";

const BankDetailsAdd = ({ setAddBank, getProfile }) => {
  const [bankDetails, setBankDetails] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);
  const user = useStore((state) => state.user);
  const verify = async () => {
    if (!bankDetails?.ifscCode || bankDetails?.ifscCode?.trim() === "") {
      toast.error("IFSC code is required");
      return;
    }
    setLoading(true);
    const [success, response] = await verifyIfsc(bankDetails.ifscCode);
    console.log(response);
    if (success) {
      setBankDetails({
        ...bankDetails,
        bankName: response.BANK,
        branchName: response.BRANCH,
      });
    }
    setLoading(false);
  };

  const addBank = async () => {
    if (!bankDetails?.ifscCode || bankDetails?.ifscCode?.trim() === "") {
      toast.error("IFSC code is required");
      return;
    }
    if (
      !bankDetails?.accountNumber ||
      bankDetails?.accountNumber?.trim() === ""
    ) {
      toast.error("Account number is required");
      return;
    }
    if (!bankDetails?.bankName || bankDetails?.bankName?.trim() === "") {
      toast.error("Bank name is required");
      return;
    }
    if (!bankDetails?.branchName || bankDetails?.branchName?.trim() === "") {
      toast.error("Branch name is required");
      return;
    }

    if (!bankDetails?.upi || bankDetails?.upi?.trim() === "") {
      toast.error("UPI ID is required");
      return;
    }

    setLoading2(true);
    const [success, response] = await addBankDetails(bankDetails, user.token);
    if (success) {
      toast.success("Bank details added");
      setAddBank(false);
      getProfile();
    }
    setLoading2(false);
  };

  return (
    <div className="mt-4 flex flex-col gap-6">
      <div className="flex gap-2 rounded-md bg-black pl-2">
        <input
          type="text"
          placeholder="IFSC Code"
          className="flex-1 rounded-md bg-secondary2 outline-none"
          value={bankDetails?.ifscCode || ""}
          onChange={(e) => {
            setBankDetails({
              ...bankDetails,
              ifscCode: e.target.value,
            });
          }}
        />
        <button
          onClick={verify}
          className="rounded-r-md bg-primary-100 px-4 py-2"
        >
          {loading ? (
            <LoaderIcon
              className={`h-6 w-6 animate-spin  ${
                loading ? "text-white" : "text-black"
              }`}
            />
          ) : (
            "Verify"
          )}
        </button>
      </div>
      <input
        type="text"
        placeholder="Account Number"
        className="rounded-md bg-secondary2 p-2"
        value={bankDetails?.accountNumber || ""}
        onChange={(e) => {
          setBankDetails({
            ...bankDetails,
            accountNumber: e.target.value,
          });
        }}
      />
      <input
        type="text"
        placeholder="Branch Name"
        value={bankDetails?.branchName || ""}
        disabled
        className="rounded-md bg-secondary2 p-2"
      />
      <input
        type="text"
        placeholder="Bank Name"
        value={bankDetails?.bankName || ""}
        className="rounded-md bg-secondary2 p-2"
        disabled
      />
      <input
        type="text"
        placeholder="Upi Id"
        value={bankDetails?.upi || ""}
        className="rounded-md bg-secondary2 p-2"
        onChange={(e) => {
          setBankDetails({
            ...bankDetails,
            upi: e.target.value,
          });
        }}
      />
      <button
        onClick={addBank}
        className="flex items-center justify-center rounded-md bg-primary-100 px-4 py-1"
      >
        {loading2 ? (
          <LoaderIcon
            className={`h-6 w-6 animate-spin  ${
              loading2 ? "text-white" : "text-black"
            }`}
          />
        ) : (
          "Add Bank"
        )}
      </button>
    </div>
  );
};

export default BankDetailsAdd;
