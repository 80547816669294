import React from "react";
import { MdOutlineSearch } from "react-icons/md";
import Loading2 from "../components/commen/Loading2";

import VideoCard from "../components/VideoCards/VideoCard";
import { searchData } from "../utils/apis";

const SearchMobile = () => {
  const [search, setSearch] = React.useState("");
  const [results, setResults] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const find = async (e) => {
    setSearch(e.target.value);
    if (e.target.value.length > 0) {
      setLoading(true);
      const [success, data] = await searchData(e.target.value);
      if (success) {
        setResults(data);
      }
      setLoading(false);
    }
  };
  return (
    <div className="flex flex-1 flex-col  gap-6 py-5">
      <div
        className={`mx-5 flex items-center gap-2 rounded bg-secondary3 px-4 py-2  text-2xl text-text  md:text-lg`}
      >
        <MdOutlineSearch />
        <input
          type="text"
          placeholder="Search"
          className={`flex flex-1 bg-transparent text-[13px] text-text outline-none md:flex `}
          value={search}
          onChange={find}
        />
      </div>
      {loading ? (
        <div className="mx-auto my-10">
          <Loading2 />
        </div>
      ) : results ? (
        <div className="flex flex-col gap-2  px-1">
          <div className="mb-6 px-4 text-xl font-bold text-white">
            Search Results
          </div>
          {results?.movies?.length > 0 && (
            <VideoCard
              data={{
                name: "Movies",
                imageType: "Horizontal",
                content: results.movies.map((item) => ({
                  item: item,
                  kind: "movie",
                })),
              }}
            />
          )}
          {results?.series?.length > 0 && (
            <VideoCard
              data={{
                name: "Series",
                imageType: "Horizontal",
                content: results.series.map((item) => ({
                  item: item,
                  kind: "series",
                })),
              }}
            />
          )}
        </div>
      ) : (
        <p className="py-10 text-center text-white">No results found</p>
      )}
    </div>
  );
};

export default SearchMobile;
