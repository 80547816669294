import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getHomePage } from "../../../utils/apis";
import useStore from "../../../utils/store";
import Footer from "./Footer";
import MobileNavbar from "./MobileNavbar";
import Navbar from "./Navbar";

const Layout = ({ children, active }) => {
  const [home, setHome] = React.useState([]);
  const setActiveCategory = useStore((state) => state.setActiveCategory);
  const isLoggedIn = useStore((state) => state.isLoggedIn);
  const user = useStore((state) => state.user);
  const [loading, setLoading] = React.useState(false);
  const location = useLocation();
  async function getHomepageHandler() {
    setLoading(true);
    const [success, response] = await getHomePage();
    if (success) {
      setHome(response);
      setActiveCategory(response[0]?._id);
    }
    setLoading(false);
  }

  useEffect(() => {
    getHomepageHandler();
  }, [user, isLoggedIn]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className="flex min-h-[100dvh] min-h-[100vh] flex-col  bg-bg">
      <MobileNavbar home={home} />
      <Navbar active={active} home={home} />
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
