import React from "react";
import { BsSearch } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";

import Logo from "../../../assets/logo.png";

import { AiOutlineClose } from "react-icons/ai";
import useStore from "../../../utils/store";
const MobileNavbar = ({ home }) => {
  const activeCategory = useStore((state) => state.activeCategory);
  const setActiveCategory = useStore((state) => state.setActiveCategory);
  const isLoggedIn = useStore((state) => state.isLoggedIn);
  const user = useStore((state) => state.user);
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  return (
    <nav className=" flex items-center justify-between bg-black px-6 py-4 md:hidden ">
      <div className="flex items-center gap-2 ">
        <button
          onClick={() => setOpen(!open)}
          className="mr-2 flex flex-col gap-[5px]"
        >
          <div className="h-[2px] w-6 rounded-full bg-white"></div>
          <div className="h-[2px] w-6 rounded-full bg-white"></div>
          <div className="h-[2px] w-6 rounded-full bg-white"></div>
        </button>
        <img
          src={Logo}
          alt="logo"
          className=" mr-auto w-16"
          onClick={() => {
            navigate("/");
          }}
        />
      </div>

      <div className="ml-auto flex gap-2">
        <button
          onClick={() => {
            navigate("/search");
          }}
          className="rounded-full bg-white bg-opacity-25 p-2.5 text-lg text-text"
        >
          <BsSearch />
        </button>
        {isLoggedIn ? (
          <button
            onClick={() => {
              navigate("/my-account");
            }}
            className="flex items-center gap-2 rounded text-sm text-text hover:text-white"
          >
            Profile
          </button>
        ) : (
          <button
            onClick={() => {
              navigate("/login");
            }}
            className="flex items-center gap-2 rounded text-sm text-text hover:text-white"
          >
            Login
          </button>
        )}
      </div>
      <div
        className={`fixed inset-0 z-40 flex flex-col items-center justify-center gap-6 bg-black bg-opacity-90 backdrop-blur-sm transition-all duration-500 ease-in-out md:hidden
      ${open ? `translate-x-0` : `-translate-x-full`} 
          `}
      >
        <button
          onClick={() => setOpen(false)}
          className="absolute right-0 top-0 p-4"
        >
          <AiOutlineClose className="text-2xl text-white" />
        </button>

        {home?.map((item) => (
          <button
            key={item._id}
            onClick={() => {
              if (location.pathname !== "/") {
                navigate("/");
              }
              setActiveCategory(item._id);
              setOpen(false);
            }}
            className={`flex items-center gap-2 rounded text-lg font-semibold ${
              activeCategory === item._id
                ? ` text-primary-100 `
                : ` text-text hover:text-white`
            }   `}
          >
            {item.name}
          </button>
        ))}
      </div>
    </nav>
  );
};

export default MobileNavbar;
