// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAyIsh9SshP2dwsiir6Xmy4jjq0YNXOeBo",
  authDomain: "wow-originals-d1ae0.firebaseapp.com",
  projectId: "wow-originals-d1ae0",
  storageBucket: "wow-originals-d1ae0.appspot.com",
  messagingSenderId: "664775476266",
  appId: "1:664775476266:web:cf13159108cf28c2436f53",
  measurementId: "G-49FLG7LHVL",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);
export const auth = getAuth(app);
