import axios from "axios";

const URL = "https://woworiginals.in";
export const url = URL;
export const getHomePage = async () => {
  try {
    const response = await axios.get(`${URL}/user/homepage`, {});

    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};
export const getSubStatus = async (jwt) => {
  try {
    const response = await axios.get(`${URL}/user/subscription-status`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};
export const getMovieDetails = async (id) => {
  try {
    const response = await axios.get(`${URL}/movie-name/${id}`);

    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const getUpcomingDetails = async (id) => {
  try {
    const response = await axios.get(`${URL}/upcoming/${id}`);

    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response];
  } catch (error) {
    return [false, error];
  }
};

export const getRecentWatch = async (token) => {
  try {
    const response = await axios.get(`${URL}/user/recentwatch`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const addRecentWatch = async (token, data) => {
  try {
    const response = await axios.post(`${URL}/user/recentwatch`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response];
  } catch (error) {
    return [false, error];
  }
};
export const addMovieView = async (token, id) => {
  try {
    const response = await axios.post(
      `${URL}/user/view/movie/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response];
  } catch (error) {
    return [false, error];
  }
};

export const addSeriesView = async (token, id) => {
  try {
    const response = await axios.post(
      `${URL}/user/view/series/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response];
  } catch (error) {
    return [false, error];
  }
};

export const getSimilarMovies = async (id) => {
  try {
    const response = await axios.get(`${URL}/user/similar-movies/${id}`);

    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};
export const generateRandomString = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;

  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};
export const getSeriesDetails = async (id) => {
  try {
    const response = await axios.get(`${URL}/series-name/${id}`);

    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const getSimilarSeries = async (id) => {
  try {
    const response = await axios.get(`${URL}/user/similar-series/${id}`);

    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const loginUser = async (data) => {
  try {
    const response = await axios.post(`${URL}/user/login`, data);

    if (response.status === 200) {
      return [true, response.data];
    }

    return [false, response];
  } catch (error) {
    return [false, error];
  }
};

export const signUpUser = async (data) => {
  try {
    const response = await axios.post(`${URL}/user/signup`, data);

    if (response.status === 200) {
      return [true, response.data];
    }

    return [false, response];
  } catch (error) {
    return [false, error];
  }
};

export const getWatchList = async (jwt) => {
  try {
    const response = await axios.get(`${URL}/user/watchlist`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });

    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const checkUserWatchlist = async (jwt, id) => {
  try {
    const response = await axios.get(`${URL}/user/watchlist/${id}`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });

    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const addToWatchList = async (jwt, data) => {
  try {
    const response = await axios.put(`${URL}/user/watchlist`, data, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });

    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const removeFromWatchList = async (jwt, data) => {
  try {
    const response = await axios.put(`${URL}/user/watchlist-remove`, data, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });

    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const getProfile = async (jwt) => {
  try {
    const response = await axios.get(`${URL}/user/profile`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};
export const getRedeem = async (jwt) => {
  try {
    const response = await axios.get(`${URL}/user/redeem-request`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const verifyIfsc = async (ifsc) => {
  try {
    const response = await axios.get(`https://ifsc.razorpay.com/${ifsc}`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response];
  } catch (error) {
    return [false, error];
  }
};
export const addBankDetails = async (data, jwt) => {
  try {
    const response = await axios.put(`${URL}/user/bank`, data, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response];
  } catch (error) {
    return [false, error];
  }
};

export const addRedeemRequest = async (jwt) => {
  try {
    const response = await axios.post(
      `${URL}/user/redeem-request`,
      {},
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response];
  } catch (error) {
    return [false, error];
  }
};

export const updateProfilePic = async (jwt, data) => {
  try {
    const response = await axios.put(`${URL}/user/update-image`, data, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const getSystemValues = async () => {
  try {
    const response = await axios.get(`${URL}/system-values`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response];
  } catch (error) {
    return [false, error];
  }
};

export const updateUserData = async (jwt, data) => {
  try {
    const response = await axios.put(`${URL}/user/profile`, data, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error.response.data];
  }
};

export const getAllRentals = async (jwt) => {
  try {
    const response = await axios.get(`${URL}/user/rented`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const getSubscription = async (jwt) => {
  try {
    const response = await axios.get(`${URL}/user/subscription-status`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const getOtherSubs = async (jwt) => {
  try {
    const response = await axios.get(`${URL}/user/other-subscriptions`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const checkAccess = async (jwt, data) => {
  try {
    const response = await axios.post(`${URL}/user/check-access`, data, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const getAllSubscriptions = async (jwt) => {
  try {
    const response = await axios.get(`${URL}/subscriptions`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const buySubscription = async (jwt, data) => {
  try {
    const response = await axios.put(`${URL}/user/buy/subscription`, data, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const updateSubscription = async (jwt, data) => {
  try {
    const response = await axios.put(`${URL}/user/update-subscription`, data, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const rentAMovie = async (jwt, data) => {
  try {
    const response = await axios.post(`${URL}/user/rent`, data, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const rentASeries = async (jwt, data) => {
  try {
    const response = await axios.post(`${URL}/user/rent`, data, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const searchData = async (data) => {
  try {
    const response = await axios.get(`${URL}/search/${data}`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const getUnreadNotifications = async (jwt) => {
  try {
    const response = await axios.get(`${URL}/user/notification/unread`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const getAllNotification = async (jwt) => {
  try {
    const response = await axios.get(`${URL}/user/notification`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const markAsRead = async (jwt, id) => {
  try {
    const response = await axios.put(`${URL}/user/notification/${id}`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const getCategoryData = async (id) => {
  try {
    const response = await axios.get(`${URL}/user/category-data/${id}`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};
export const getSubscriptionHistory = async (jwt) => {
  try {
    const response = await axios.get(`${URL}/user/subscription-history`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const getAllGenre = async () => {
  try {
    const response = await axios.get(`${URL}/admin/genres`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const getAllMovies = async () => {
  try {
    const response = await axios.get(`${URL}/movies`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const getAllSeries = async () => {
  try {
    const response = await axios.get(`${URL}/series`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};
export const phonePay = async (data, jwt) => {
  try {
    const response = await axios.post(`${URL}/phonepay`, data, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });

    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response];
  } catch (error) {
    return [false, error];
  }
};

export const getUpcoming = async () => {
  try {
    const response = await axios.get(`${URL}/upcoming`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const getPoster = async () => {
  try {
    const response = await axios.get(`${URL}/single-banner`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const addReferral = async (jwt, data) => {
  try {
    const response = await axios.post(`${URL}/user/referral`, data, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });

    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response];
  } catch (error) {
    return [false, error];
  }
};

export const getReferralChain = async (id) => {
  try {
    const response = await axios.get(`${URL}/chain/${id}`);

    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response];
  } catch (error) {
    return [false, error];
  }
};

export const updateKyc = async (jwt, data) => {
  try {
    const response = await axios.put(`${URL}/user/kyc`, data, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });

    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response];
  } catch (error) {
    return [false, error];
  }
};
