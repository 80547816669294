import React from "react";
// Import Swiper React components
import { useNavigate } from "react-router-dom";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

const Banners = ({ data }) => {
  const swiperRef = React.useRef(null);
  const navigate = useNavigate();
  const [active, setActive] = React.useState(0);
  return (
    <Swiper
      modules={[Autoplay]}
      spaceBetween={30}
      slidesPerView={1.5}
      breakpoints={{
        240: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        768: {
          slidesPerView: 1.5,
          spaceBetween: 40,
        },
      }}
      centeredSlides={true}
      onSlideChange={() => {
        setActive(swiperRef.current.activeIndex);
      }}
      onSwiper={(swiper) => {}}
      onBeforeInit={(swiper) => {
        swiperRef.current = swiper;
      }}
      //interaction to stop

      autoplay={{
        delay: 5000,
        disableOnInteraction: true,
      }}
      loop
      className="w-full"
    >
      {data
        ?.filter((item) => item.isEnable)
        ?.map((item, index) => (
          <SwiperSlide key={index} className="relative ">
            <div
              className="relative flex cursor-pointer p-4 md:p-6"
              onClick={(e) => {
                e.preventDefault();
                if (item?.link?.kind === "movie") {
                  navigate(`/movie/${item?.link?.item?.slug}`);
                } else {
                  navigate(`/series/${item?.link?.item?.slug}`);
                }
              }}
            >
              <div className=" absolute left-24 top-1/2 hidden -translate-y-1/2 flex-col items-center justify-center gap-2 md:flex "></div>
              <img
                src={item.horizontalBanner}
                alt={item._id}
                className=" aspect-[16/9] w-full rounded-xl bg-black object-fill"
              />
            </div>
          </SwiperSlide>
        ))}
      <div className="absolute right-2 top-[45%] z-10">
        <button
          className="rounded-full bg-primary-100 bg-opacity-30 p-2 shadow-xl"
          onClick={() => {
            swiperRef.current?.slideNext();
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5l7 7-7 7"
            />
          </svg>
        </button>
      </div>
      <div className="absolute left-2 top-[45%] z-10">
        <button
          className="rounded-full bg-primary-100  bg-opacity-30 p-2 shadow-xl"
          onClick={() => {
            swiperRef.current?.slidePrev();
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </button>
      </div>
    </Swiper>
  );
};

export default Banners;
