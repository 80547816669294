import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../components/commen/Loading";

import Banners from "../components/VideoCards/Banners";
import VideoCard from "../components/VideoCards/VideoCard";
import { getCategoryData } from "../utils/apis";
import useStore from "../utils/store";

const Category = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState([]);
  const user = useStore((state) => state.user);
  const getCategoryDataHandler = async () => {
    setLoading(true);
    const [success, data] = await getCategoryData(id);
    if (success) {
      setData(data);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    getCategoryDataHandler();
  }, [id]);

  if (loading) return <Loading />;
  return (
    <div>
      <Banners data={data?.banners} />
      {data?.subcategories?.map((item) => {
        return <VideoCard data={item} />;
      })}
    </div>
  );
};

export default Category;
