import React from "react";
import Static, { Item } from "../../components/commen/Static";

const About = () => {
  return (
    <Static title={"About Us"}>
      <Item title="">
        About Us We present you India’s most exciting video streaming app! Get
        ready to hinge and binge with our amazing range of content! The WOW App
        under Production House Visual Writers is a subscription-based video on demand
        service. It is a video streaming app to please all your tastes and the
        exciting part is that we present videos in your own language!
        <br />
        <br />
        We have got:
        <br />
        <br />
        1. Amazing feature length films for you to stream 24/7!
        <br />
        2. Web Series that are adventurous and exciting!
        <br />
        3. Short Films that will really stop your breath!
        <br />
        4. Latest music videos!
        <br />
        5. Unlimited HD-Streaming.
        <br />
        6. Access anytime and anywhere.
      </Item>
    </Static>
  );
};

export default About;
