import React from "react";
import useStore from "../../utils/store";
import {
  addRedeemRequest,
  getProfile,
  getRedeem,
  getSystemValues,
} from "../../utils/apis";
import { IoWalletSharp } from "react-icons/io5";
import { VscLoading } from "react-icons/vsc";
import toast from "react-hot-toast";
import BankDetailsAdd from "../../components/BankDetailsAdd";
const Wallet = () => {
  const [profile, setProfile] = React.useState({});
  const [loading2, setLoading2] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [addBank, setAddBank] = React.useState(false);

  const [systemValues, setSystemValues] = React.useState({});
  const [redeemRequest, setRedeemRequest] = React.useState({
    isOpen: true,
  });
  const user = useStore((state) => state.user);

  const getProfileHandler = async (update) => {
    setLoading2(true);
    const [success, response] = await getProfile(user.token);
    if (success) {
      setProfile(response);
    }
    setLoading2(false);
  };

  const getRedeemRequestHandler = async () => {
    setLoading(true);
    const [success, response] = await getRedeem(user.token);
    if (success) {
      setRedeemRequest(response);
    }
    setLoading(false);
  };

  const getSystemValuesHandler = async () => {
    const [success, response] = await getSystemValues();

    if (success) {
      setSystemValues(response);
    }
  };
  console.log(profile);
  const addRedeemRequestHandler = async () => {
    if (redeemRequest.isOpen) {
      toast.error("Redeem request already open");
      return;
    }
    if (!(systemValues?.redeemAmount <= profile?.wallet)) {
      toast.error("Minimum redeem amount not reached");
      return;
    }

    toast.promise(addRedeemRequest(user.token), {
      loading: "Sending request...",
      success: (data) => {
        const [success, response] = data;
        if (success) {
          getRedeemRequestHandler();
          return "Request sent";
        }
        return response?.response?.data?.message;
      },
      error: (err) => {
        console.log(err);
        return "Error sending request";
      },
    });
  };

  const addBankDetailHandler = () => {
    console.log("add bank detail");
  };

  React.useEffect(() => {
    getProfileHandler();
    getRedeemRequestHandler();
    getSystemValuesHandler();
  }, []);
  return (
    <main className="flex min-h-[100dvh] items-center justify-center">
      <section className="rounded-lg bg-secondary3 p-5 text-white md:w-96">
        <div className="flex items-center justify-between">
          <h1 className="flex items-center gap-2 text-2xl font-bold">
            <IoWalletSharp />
            Wallet
          </h1>
          {!addBank ? (
            <button
              onClick={
                profile?.isBankDetailAdded
                  ? addRedeemRequestHandler
                  : () => {
                      setAddBank(true);
                    }
              }
              className="rounded-md bg-primary-100 px-4 py-1"
            >
              Redeem
            </button>
          ) : (
            <button
              onClick={() => {
                setAddBank(false);
              }}
              className="rounded-md bg-primary-100 px-4 py-1"
            >
              Back
            </button>
          )}
        </div>
        {loading2 || loading ? (
          <div className="flex items-center justify-center">
            <VscLoading className="h-10 w-10 animate-spin text-white" />
          </div>
        ) : addBank ? (
          <BankDetailsAdd
            setAddBank={setAddBank}
            getProfile={getProfileHandler}
          />
        ) : (
          <div className="mt-4">
            <div className="flex items-center justify-between">
              <h1 className="text-xl font-bold">Balance</h1>
              <h1 className="text-xl font-bold">₹{profile?.wallet || 0}</h1>
            </div>
            <div className="my-4 h-0.5 bg-gray-400"></div>
            <div className="mt-4  flex flex-col  gap-3">
              <h1 className="text-xl font-bold">History</h1>
              {profile?.walletHistory?.map((item, index) => (
                <p key={index} className="flex items-center justify-between">
                  {item}
                </p>
              ))}
              {!profile?.walletHistory?.length && <p>No history found</p>}
            </div>
          </div>
        )}
      </section>
    </main>
  );
};

export default Wallet;
